<template>
  <div>
      <title-box>
          <div class="row">
              <div class="col-12">
                  <h1>Configuration id: {{configurationData.accountConfigurationId}}</h1>
              </div>
          </div>
      </title-box>

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-9">
                    <mercur-card class="mt-n4 mb-4 font-weight-normal">
                        <h2 class="mt-1 font-weight-normal">General information
                            <mercur-button v-if="configurationData.accountConfigurationType === 'SYSTEM'" class="btn btn-icon" @click.native="isEditing = true; isUpdating = true">
                                <i class="fas fa-edit"></i>
                            </mercur-button>
                        </h2>
                        <div>Configuration type: <b>{{configurationData.accountConfigurationType}}</b></div>
                        <pretty-select
                            v-if="configurationData.accountConfigurationType === 'SYSTEM' && isEditing"
                            class="mb-3"
                            placeholder="Select System Configuration"
                            :required="true"
                            :options="systemConfigurations"
                            :get-option-label="configuration => configuration.accountConfigurationName"
                            v-model="selectedSystemConfiguration">
                        </pretty-select>
                        <div>Configuration name: <b>{{configurationData.accountConfigurationName}}</b></div>
                        <div v-if="configurationData.schema">Schema type: <b>{{configurationData.schema.type}}</b></div>
                        <mercur-button v-if="isEditing" @click.native="isEditing = false" class="btn btn-primary">Save</mercur-button>
                    </mercur-card>
                    <mercur-card v-if="configurationData.schema">
                        <account-configuration-schema-collection v-model="configurationData.schema.fields"></account-configuration-schema-collection>
                    </mercur-card>
                    <div class="text-right mt-4">
                        <mercur-button class="btn btn-primary" @click.native="update">Update</mercur-button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import CONFIG from '@root/config'
import PrettySelect from '@/components/utils/PrettySelect'
import AccountConfigurationSchemaCollection from '../../../components/elements/customers/AccountConfigurationFields/AccountConfigurationSchemaCollection'
export default {
    components: { PrettySelect, AccountConfigurationSchemaCollection },
    data () {
        return {
            configurationData: {},
            systemConfigurations: {},
            selectedSystemConfiguration: {},
            isEditing: false,
            isEditingFields: false,
            isUpdating: false,
            newSchemaValues: [],
        }
    },
    created () {
        this.get(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.GET.OVERVIEW.replace('{accountConfigurationId}', this.$route.params.configurationId))
            .then(({ data }) => {
                this.configurationData = data
            })

        // Get a list of all system configurations
        this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.FILTERED_OVERVIEW
            .replace('{configurationType}', 'SYSTEM'),
        CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.$set(this, 'systemConfigurations', data)
        })
    },
    watch: {
        selectedSystemConfiguration (val) {
            this.configurationData = val
        },
    },
    methods: {
        update () {
            this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.UPDATE, this.configurationData).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Account configuration was updated',
                })
                this.isUpdating = false
            })
        },
        addFieldValue (val, fieldIndex) {
            if (val !== '' && val !== undefined) {
                this.configurationData.schema.fields[fieldIndex].values.push(val)
            }
        },
    },
}
</script>

<style lang="scss">
  .account-configurations {
    &__schema-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
    &__icon {
      margin: 0;
    }
    &__schema-value-input {
      margin-right: 20px;
      width: 50px;
    }
  }
</style>
